import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useItemList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'nome',
      label: 'Nome',
      sortable: true,
    },

    {
      key: 'tipo.nome',
      label: 'Tipo Registro',
      sortable: true,
    },

    { key: 'Ações' },
  ]
  const perPage = ref(10)
  const totalItens = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    // eslint-disable-next-line no-unused-expressions
    currentPage.value - 1

    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData()
  })

  const fetchDados = (ctx, callback) => {
    store
      .dispatch('app-registros/fetchDados', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,

      })
      .then(response => {
        callback(response.data)
        totalItens.value = response.data.totalItens
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao criar o sub tipo de registro',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteItem = id => {
    store.dispatch('app-registros/deleteItem', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Tipo de Registro excluído com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
  }
  const update = item => {
    store.dispatch('app-registros/update', item)
      .then(() => {
        refetchData()

        toast({
          component: ToastificationContent,
          props: {
            title: 'Tipo Registro editado com sucesso ',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
  }
  const alterarStatus = item => {
    console.log(item)
    store.dispatch('app-registros/alterarStatus', item)
      .then(() => {
        window.history.back()

        toast({
          component: ToastificationContent,
          props: {
            title: 'Status alterado com sucesso ',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
  }

  function safeDecodeBase64(base64String) {
    const base64EncodedData = base64String.split(',')[1] || base64String

    if (!/^[A-Za-z0-9+/=]+$/.test(base64EncodedData)) {
      console.error('Base64 é inválido.')
      return null
    }

    try {
      return atob(base64EncodedData)
    } catch (error) {
      console.error('Erro no decoding do Base64:', error)
      return null
    }
  }

  function downloadPdf(base64Data, fileName) {
    const decodedData = safeDecodeBase64(base64Data)
    if (!decodedData) {
      console.error('Failed to decode Base64 data.')
      return
    }

    const byteNumbers = new Array(decodedData.length)
    for (let i = 0; i < decodedData.length; i++) {
      byteNumbers[i] = decodedData.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const fileBlob = new Blob([byteArray], { type: 'application/pdf' })

    const blobUrl = URL.createObjectURL(fileBlob)
    const downloadLink = document.createElement('a')
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    downloadLink.href = blobUrl
    downloadLink.download = fileName
    downloadLink.click()
    document.body.removeChild(downloadLink)
    URL.revokeObjectURL(blobUrl)
  }

  const gerarDetalhamento = item => {
    store.dispatch('app-registros/gerarDetalhamento', item)
      .then(resp => {
        const base64Data = resp.data.file
        const fileName = `${resp.data.name}.pdf`
        downloadPdf(base64Data, fileName)
        Swal.close()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Detalhamento Gerado com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        console.error('Error generating PDF:', error)
      })
  }

  return {
    fetchDados,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
    update,
    alterarStatus,
    gerarDetalhamento,
  }
}
